import React from 'react';

export interface SvgProps {
  width?: string;
  height?: string;
  size?: string;
}

export default function InviteTwitter(svgProps: SvgProps) {
  return (
    <svg
      width={svgProps.width || '48'}
      height={svgProps.height || '48'}
      viewBox={`0 0 ${svgProps.size || 48} ${svgProps.size || 48}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.5" cy="24" r="24" fill="#F8F9FA" />
      <path
        d="M35.5344 19.2845L32.7031 22.1063C32.1406 28.6595 26.6094 33.7501 20 33.7501C18.6406 33.7501 17.5156 33.5345 16.6625 33.1126C15.9781 32.7657 15.6969 32.4001 15.6219 32.2876C15.5598 32.1933 15.5198 32.0863 15.5049 31.9744C15.49 31.8626 15.5005 31.7488 15.5356 31.6416C15.5707 31.5344 15.6296 31.4364 15.7079 31.3551C15.7861 31.2738 15.8817 31.2112 15.9875 31.172C16.0063 31.1626 18.2188 30.3188 19.6531 28.697C18.7636 28.0636 17.9817 27.2912 17.3375 26.4095C16.0531 24.6657 14.6938 21.6376 15.5094 17.1188C15.535 16.9845 15.5965 16.8596 15.6872 16.7573C15.778 16.655 15.8947 16.5791 16.025 16.5376C16.1557 16.4948 16.2957 16.4888 16.4297 16.5202C16.5636 16.5516 16.6863 16.6192 16.7844 16.7157C16.8125 16.7532 19.9344 19.8282 23.75 20.822V20.2501C23.7537 19.6554 23.8745 19.0673 24.1054 18.5194C24.3364 17.9714 24.673 17.4743 25.0961 17.0564C25.5192 16.6386 26.0205 16.3081 26.5713 16.084C27.1221 15.8598 27.7116 15.7464 28.3063 15.7501C29.0867 15.7612 29.851 15.9743 30.5246 16.3686C31.1982 16.7629 31.7582 17.325 32.15 18.0001H35C35.1481 17.9996 35.293 18.043 35.4164 18.1247C35.5399 18.2065 35.6364 18.323 35.6938 18.4595C35.7478 18.598 35.7615 18.749 35.7333 18.895C35.7051 19.041 35.6361 19.176 35.5344 19.2845Z"
        fill="#093F76"
      />
    </svg>
  );
}
