import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import { SOTFlowType } from '../types';
import Splash from './Splash';
import WhatsNext from './WhatsNext';
import Verify from './Verify';
import VerificationCode from './VerificationCode';
import LetsBegin from './LetsBegin';
import Vouch from './Vouch';
import Describe from './Describe';
import PositiveTraits from './PositiveTraits';
import NeutralTraits from './NeutralTraits';
import DominantTrait from './DominantTrait';
import ShareInsight from './ShareInsight';
import ShareMedia from './ShareMedia';
import ShareMediaReview from './ShareMediaReview';
import Review from './Review';
import Contest from './Contest';

const Stack = createNativeStackNavigator<SOTFlowType>();
export default function SphereOfTrustFlow() {
  return (
    <Stack.Navigator>
      <Stack.Screen name="SplashScreen" component={Splash} options={{ headerShown: false }} />
      <Stack.Screen name="WhatsNext" component={WhatsNext} options={{ headerShown: false }} />
      <Stack.Screen name="Verify" component={Verify} options={{ headerShown: false }} />
      <Stack.Screen
        name="VerificationCode"
        component={VerificationCode}
        options={{ headerShown: false }}
      />
      <Stack.Screen name="LetsBegin" component={LetsBegin} options={{ headerShown: false }} />
      <Stack.Screen name="Vouch" component={Vouch} options={{ headerShown: false }} />
      <Stack.Screen name="Describe" component={Describe} options={{ headerShown: false }} />
      <Stack.Screen
        name="PositiveTraits"
        component={PositiveTraits}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="NeutralTraits"
        component={NeutralTraits}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="DominantTrait"
        component={DominantTrait}
        options={{ headerShown: false }}
      />
      <Stack.Screen name="ShareInsight" component={ShareInsight} options={{ headerShown: false }} />
      <Stack.Screen name="ShareMedia" component={ShareMedia} options={{ headerShown: false }} />
      <Stack.Screen
        name="ShareMediaReview"
        component={ShareMediaReview}
        options={{ headerShown: false }}
      />
      <Stack.Screen name="Review" component={Review} options={{ headerShown: false }} />
      <Stack.Screen
        name="ContestScreen"
        component={Contest}
        options={{ headerShown: false, presentation: 'modal' }}
      />
    </Stack.Navigator>
  );
}

LetsBegin: undefined;
Vouch: undefined;
Describe: undefined;
PositiveTraits: undefined;
NeutralTraits: undefined;
DominantTrait: undefined;
ShareInsight: undefined;
ShareMedia: undefined;
ShareMediaReview: undefined;
