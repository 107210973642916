import React from 'react';

export interface SvgProps {
  width: string;
  height: string;
  size: string;
}

export default function VerifyIcon(svgProps: SvgProps) {
  return (
    <svg
      width={svgProps.width || '274'}
      height={svgProps.height || '274'}
      viewBox={`0 0 ${svgProps.size || '274'} ${svgProps.size || '274'}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M175.414 131.979C157.378 142.72 135.375 130.064 136.409 129.844C170.506 122.43 173.519 100.919 173.519 100.919C166.712 84.2871 148.157 89.6414 148.157 89.6414C147.62 113.244 119.527 114.573 119.527 114.573C117.723 106.915 117.822 89.0346 117.822 89.0346C109.308 83.0815 107.584 85.6082 107.584 85.6082C110.269 61.9785 121.082 54.3606 121.082 54.3606C122.884 52.6823 130.937 48.9096 135.072 48.4209L135.22 49.0761C135.22 49.0761 131.039 59.5161 139.051 60.2036C145.794 60.7943 149.626 52.4943 150.67 49.8602C159.438 52.5776 167.805 58.6596 173.371 68.8902C186.539 93.0946 175.414 131.979 175.414 131.979Z"
        fill="#093F76"
      />
      <path
        d="M177.364 121.794L221.189 150.794C229.688 156.417 228.595 169.215 219.275 173.315L167.987 195.887C158.287 200.155 147.064 199.056 138.375 192.989L118.679 179.22"
        stroke="#212529"
        strokeWidth="1.15354"
      />
      <path
        d="M177.364 131.147L175.89 130.169L221.197 160.141C229.696 165.764 228.603 178.562 219.283 182.662L167.995 205.234C158.295 209.502 147.072 208.404 138.383 202.337L113.341 184.502"
        stroke="#212529"
        strokeWidth="1.15354"
      />
      <path
        d="M215.502 171.715L168.363 192.463C159.448 196.385 149.134 195.375 141.149 189.8L123.085 177.109C125.572 175.203 128.359 173.724 131.332 172.733C138.778 170.203 146.044 167.174 153.082 163.665C163.898 158.314 171.763 148.416 174.531 136.67C175.476 132.656 176.306 128.596 176.985 124.514L218.472 151.938C226.28 157.105 224.065 167.95 215.502 171.715Z"
        fill="#ADB5BD"
      />
      <path
        d="M94.0369 231.847C94.0369 231.847 102.302 228.421 103.886 225.838C103.886 225.838 104.184 231.702 102.638 233.706C101.091 235.709 91.8485 241.004 91.3517 240.341C90.855 239.677 94.0369 231.847 94.0369 231.847Z"
        fill="#ADB5BD"
      />
      <path
        d="M68.5946 245.821C68.5946 245.821 70.0983 247.862 75.4338 245.821C80.7693 243.78 92.3774 236.264 92.3774 236.264C92.3774 236.264 89.1391 244.959 87.9656 245.915C86.7922 246.871 64.6232 257.73 64.6232 257.73C64.6232 257.73 54.7121 259.14 58.4472 252.091L68.5946 245.821Z"
        fill="#ADB5BD"
      />
      <path
        d="M100.5 132.215C88.2824 136.971 75.3532 148.144 79.0722 179.085L80.106 187.125"
        stroke="#212529"
        strokeWidth="1.15354"
      />
      <path
        d="M100.481 112.009C102.479 109.157 104.283 106.075 105.39 102.756C106.496 99.4368 106.552 95.8413 106.861 92.3505C107.683 82.9899 108.956 75.2485 112.567 66.5296C115.696 58.9842 125.145 49.2638 134.546 48.3428L135.391 48.2703"
        stroke="#212529"
        strokeWidth="1.15354"
      />
      <path
        d="M124.111 131.53C124.065 131.399 124.02 131.275 123.971 131.146"
        stroke="#212529"
        strokeWidth="1.15354"
      />
      <path
        d="M119.527 113.993C119.065 111.278 118.722 108.647 118.453 106.15C118.454 106.103 118.449 106.056 118.437 106.01C117.03 92.0658 118.453 81.9211 118.453 81.9211"
        stroke="#212529"
        strokeWidth="1.15354"
      />
      <path
        d="M152.37 29.3614L150.222 30.1992C150.222 30.2663 150.222 31.1041 150.131 32.2775C150.031 33.679 149.808 35.0691 149.465 36.4316C149.655 35.5025 149.932 33.2952 148.718 31.7593C148.699 31.7279 148.678 31.6983 148.654 31.6707C147.848 30.7013 146.855 31.0853 146.057 31.7512C145.506 32.2173 145.025 32.7598 144.629 33.3624C144.768 31.5685 144.446 29.7689 143.694 28.1343C143.204 27.0192 142.553 25.9816 141.763 25.0544C142.98 29.2433 141.42 31.9849 141.42 31.9849C137.298 32.9542 134.438 32.7475 132.306 32.3796C130.51 30.704 131.133 24.6704 131.133 24.6704C129.326 26.1472 129.761 31.8613 129.761 31.8613C129.53 30.3702 129.558 28.8505 129.844 27.369C128.946 28.6385 128.428 30.1367 128.348 31.6895L128.316 31.6734C128.191 31.6414 128.069 31.6029 127.948 31.5579C127.727 31.4416 127.513 31.3125 127.306 31.1712L126.117 30.3657C127.913 21.2011 134.194 15.4387 143.898 17.1303C144.532 17.2395 145.155 17.4012 145.762 17.6137C152.155 20.946 152.37 29.3614 152.37 29.3614Z"
        fill="#ADB5BD"
      />
      <path
        d="M96.9905 118.617L94.5442 111.255C94.4341 110.906 94.1519 110.639 93.7978 110.548C93.7763 110.548 93.7521 110.548 93.7279 110.532C93.7038 110.516 93.6984 110.532 93.6823 110.532C93.626 110.529 93.5695 110.529 93.5131 110.532C93.2418 110.533 92.9808 110.637 92.7828 110.822C92.3812 111.193 92.1525 111.715 92.1517 112.262C92.1519 112.473 92.1854 112.683 92.2511 112.885C92.7881 114.439 93.7441 117.245 94.1979 118.523"
        fill="#ADB5BD"
      />
      <path
        d="M96.4373 118.617L93.8005 110.548C93.779 110.548 93.7548 110.548 93.7307 110.532C93.7065 110.516 93.7011 110.532 93.685 110.532L92.3021 110.264C91.5637 110.127 90.8253 109.979 90.0868 109.842C89.2007 109.665 88.3092 109.517 87.4258 109.305C87.2173 109.246 87.003 109.21 86.7867 109.198C86.5145 109.197 86.2527 109.302 86.0564 109.49C85.6512 109.861 85.4219 110.386 85.4253 110.935C85.424 111.144 85.4576 111.352 85.5247 111.55C86.1181 113.29 87.2486 116.582 87.6084 117.581L96.4373 118.617Z"
        fill="#ADB5BD"
      />
      <path
        d="M148.573 81.9188C148.305 84.9075 148.165 88.5244 148.071 93.0705C148.071 102.737 137.384 110.742 127.886 112.573L110.47 116.507C108.169 116.953 106.131 115.516 104.531 114.563C102.885 113.583 101.198 112.415 100.011 111.547C98.3036 110.299 95.0142 111.303 95.6882 114.281C95.9827 115.429 96.796 116.373 97.8874 116.834C98.1906 116.97 98.5028 117.085 98.8219 117.178C99.0662 117.239 99.2388 117.457 99.2426 117.709C99.2464 117.961 99.0803 118.184 98.838 118.252C97.3289 118.773 94.5658 119.082 90.1084 117.447C84.397 115.344 83.9539 128.378 91.7625 130.647H91.792C98.9857 132.822 106.727 132.543 114.162 132.231C122.606 131.912 131.007 130.887 139.28 129.168C143.487 128.276 147.811 127.35 151.661 125.446C158.294 122.167 163.073 118.408 167.619 112.557C172.721 105.997 174.899 97.391 176.129 89.3219C177.536 80.0902 175.592 72.2951 170.643 64.3496L170.46 64.0596"
        stroke="#212529"
        strokeWidth="1.15354"
      />
      <path
        d="M96.9906 118.617L94.5444 111.254C94.4342 110.906 94.152 110.639 93.7979 110.548C93.7764 110.548 93.7522 110.548 93.7281 110.532C93.7039 110.516 93.6985 110.532 93.6824 110.532C93.6261 110.528 93.5696 110.528 93.5133 110.532C93.2419 110.533 92.981 110.637 92.7829 110.822C92.3813 111.193 92.1526 111.715 92.1519 112.261C92.152 112.473 92.1856 112.683 92.2512 112.884C92.7882 114.439 93.7442 117.245 94.198 118.523"
        stroke="#212529"
        strokeWidth="1.15354"
      />
      <path
        d="M93.8005 110.548C93.779 110.548 93.7548 110.548 93.7307 110.532C93.7065 110.516 93.7011 110.532 93.685 110.532L92.3021 110.264C91.5637 110.127 90.8253 109.979 90.0868 109.842C89.2007 109.665 88.3092 109.517 87.4258 109.305C87.2173 109.246 87.003 109.21 86.7867 109.198C86.5145 109.197 86.2527 109.302 86.0564 109.49C85.6512 109.861 85.4219 110.386 85.4253 110.935C85.424 111.144 85.4576 111.352 85.5247 111.55C86.1181 113.29 87.2486 116.582 87.6084 117.581"
        stroke="#212529"
        strokeWidth="1.15354"
      />
      <path
        d="M127.513 31.4263C127.513 34.917 128.193 38.2547 129.868 41.3373C131.211 43.8131 133.182 46.3076 136.001 47.1266C136.626 47.3069 137.273 47.3973 137.924 47.3952C142.709 47.3737 143.455 41.7052 143.455 41.7052"
        stroke="#212529"
        strokeWidth="1.15354"
      />
      <path
        d="M149.97 33.4509V33.467C149.748 35.1991 149.367 36.9069 148.831 38.5689C148.191 40.5514 148.04 42.6592 148.391 44.7127L149.134 49.0385C149.204 49.4318 149.491 49.751 149.876 49.8602C159.317 52.6018 167.697 58.6515 172.574 67.3865C178.656 78.2938 179.765 91.4298 179.273 103.674C178.841 114.289 177.125 124.842 174.69 135.172C171.675 147.954 163.116 158.726 151.344 164.55C143.694 168.371 135.793 171.668 127.696 174.419C119.016 177.293 112.091 183.924 108.843 192.471L89.8157 243.066C89.1164 244.933 87.7372 246.468 85.9544 247.362L67.7219 256.521C65.4195 257.674 62.7931 258.007 60.2758 257.466C58.9402 257.178 57.9169 256.102 57.6963 254.754C57.4757 253.405 58.1027 252.059 59.2769 251.36L68.5892 245.821L81.3949 180.538C83.2186 168.752 90.1066 158.352 100.248 152.075C100.916 151.668 101.612 151.309 102.331 151.001L130.091 138.947"
        stroke="#212529"
        strokeWidth="1.15354"
      />
      <path
        d="M142.338 16.4993C140.854 16.1456 139.318 16.0673 137.806 16.2684C137.743 16.2698 137.682 16.2751 137.62 16.2845"
        stroke="#212529"
        strokeWidth="1.15354"
      />
      <path
        d="M135.614 48.429C135.614 48.429 130.703 59.688 139.486 60.2438C147.368 60.7487 150.259 50.0401 150.259 50.0401"
        stroke="#212529"
        strokeWidth="1.15354"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M149.352 36.8423C149.408 36.6356 150.254 33.5825 148.638 31.6599C147.021 29.7373 144.83 33.0347 144.61 33.365C145.039 28.631 141.75 25.0409 141.75 25.0409C142.964 29.2325 141.401 31.9714 141.401 31.9714C135.394 33.3784 132.089 32.307 129.745 31.8452C129.514 30.3549 129.543 28.8359 129.831 27.3555C128.93 28.6218 128.411 30.119 128.335 31.6707H128.297C127.405 31.4335 126.559 31.0477 125.795 30.5294L124.605 29.7239C126.404 20.562 132.682 14.7942 142.386 16.4858C152.091 18.1775 152.362 29.3372 152.362 29.3372L150.2 30.1777C150.2 30.1777 150.219 33.8618 149.352 36.8423V36.8423Z"
        stroke="#212529"
        strokeWidth="1.15354"
      />
      <path
        d="M131.278 36.3643V40.819C131.279 40.929 131.332 41.0321 131.421 41.0967C131.51 41.1613 131.624 41.1799 131.729 41.1466C132.079 41.0306 132.401 40.8421 132.674 40.5934"
        stroke="#212529"
        strokeWidth="1.15354"
      />
      <ellipse
        cx="127.235"
        cy="36.2086"
        rx="2.73085"
        ry="3.79687"
        transform="rotate(-21.3 127.235 36.2086)"
        stroke="#212529"
        strokeWidth="1.15354"
      />
      <ellipse
        cx="135.69"
        cy="37.4825"
        rx="2.73085"
        ry="3.79687"
        transform="rotate(-21.3 135.69 37.4825)"
        stroke="#212529"
        strokeWidth="1.15354"
      />
      <path
        d="M130.096 36.5954C130.096 36.5954 130.929 35.6448 132.674 36.7591"
        stroke="#212529"
        strokeWidth="1.15354"
      />
      <path
        d="M135.22 46.8447C135.22 46.8447 135.824 48.8908 137.601 55.5233"
        stroke="#212529"
        strokeWidth="1.15354"
      />
      <path
        d="M90.7152 240.676L101.187 235.024C102.406 234.363 103.219 233.143 103.36 231.764C105.1 214.672 104.568 203.867 104.568 203.867"
        stroke="#212529"
        strokeWidth="1.15354"
      />
      <path d="M81.2096 153.026L40.9075 124.861" stroke="#212529" strokeWidth="1.15354" />
      <path d="M78.9272 160.786L40.9075 134.213" stroke="#212529" strokeWidth="1.15354" />
      <path
        d="M233.093 107.251V130.309C233.093 132.469 231.819 134.426 229.843 135.301L201.246 147.964L192.503 161.25C192.041 161.927 191.195 162.226 190.411 161.991C189.627 161.756 189.085 161.041 189.071 160.222V153.359L186.139 154.656C184.814 155.235 183.287 155.109 182.075 154.32C180.863 153.532 180.129 152.187 180.122 150.741V131.485C180.122 126.577 183.037 122.137 187.541 120.186L226.841 103.148C228.224 102.548 229.815 102.685 231.075 103.512C232.335 104.338 233.093 105.744 233.093 107.251Z"
        fill="#EE6055"
      />
      <ellipse
        cx="194.61"
        cy="135.625"
        rx="5.54225"
        ry="3.83178"
        transform="rotate(-62.6 194.61 135.625)"
        fill="white"
      />
      <ellipse
        cx="206.824"
        cy="130.398"
        rx="5.54225"
        ry="3.83178"
        transform="rotate(-62.6 206.824 130.398)"
        fill="white"
      />
      <ellipse
        cx="218.626"
        cy="124.753"
        rx="5.54225"
        ry="3.83178"
        transform="rotate(-62.6 218.626 124.753)"
        fill="white"
      />
    </svg>
  );
}
