import { StyleSheet, TouchableOpacity } from 'react-native';
import React from 'react';
import { Text, View } from '../../components/Themed';
import { globalStyles } from '../../Global/GlobalStyles';

export interface ButtonStandardProps {
  width?: string;
  height?: string;
  label?: string;
  onPress: any;
  disabled?: boolean;
}

export default function ButtonStandard(svgProps: ButtonStandardProps) {
  return (
    <TouchableOpacity
      style={{ width: '100%', alignItems: 'center' }}
      onPress={
        svgProps.disabled
          ? () => {
              return false;
            }
          : svgProps.onPress
      }
    >
      <View style={svgProps.disabled ? styles.buttonDisabled : styles.button}>
        <Text style={svgProps.disabled ? styles.buttonTextDisabled : styles.buttonTextDisabled}>
          {svgProps.label}
        </Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  button: {
    width: '100%',
    height: globalStyles.container.height < 800 ? 50 : 58,
    backgroundColor: 'background: rgba(9, 63, 118, 1)',
    borderRadius: 80,
  },
  buttonText: {
    color: '#FFFFFF',
    fontSize: 24,
    fontFamily: 'Jost Regular',
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'center',
    fontWeight: '600',
  },
  buttonDisabled: {
    width: '100%',
    height: globalStyles.container.height < 800 ? 50 : 58,
    backgroundColor: '#ACB5BD',
    borderRadius: 80,
  },
  buttonTextDisabled: {
    color: '#d7dde3',
    fontSize: globalStyles.container.height < 800 ? 22 : 24,
    fontFamily: 'Jost Regular',
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'center',
    fontWeight: '600',
  },
  title: {
    fontSize: 22,
    textAlign: 'left',
  },
});
