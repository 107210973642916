import { StyleSheet, TouchableOpacity } from 'react-native';
import React from 'react';
import { Text, View } from '../../components/Themed';
import { globalStyles } from '../../Global/GlobalStyles';

export interface ButtonInvertedProps {
  width?: string;
  height?: string;
  label?: string;
  onPress: any;
  style?: any;
  subStyle?: any;
}

export default function ButtonInverted(svgProps: ButtonInvertedProps) {
  return (
    <TouchableOpacity style={[{ width: '100%', alignItems: 'center' }, svgProps.style]} onPress={svgProps.onPress}>
      <View style={[styles.button, svgProps.subStyle]}>
        <Text style={styles.buttonText}>{svgProps.label}</Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  button: {
    width: 293,
    height: globalStyles.container.height < 800 ? 50 : 58,
    backgroundColor: '#FFFFFF',
    borderRadius: 80,
    bottom: 50,
    left: 0,
  },
  buttonText: {
    color: '#093F76',
    fontSize: 24,
    fontFamily: 'Jost Regular',
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'center',
  },
  title: {
    fontSize: 22,
    textAlign: 'left',
  },
});
