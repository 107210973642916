export const environment = {
  serverUrl: 'https://api.datingsphere.com',
  //serverUrl: 'https://datingsphere-1063.appspot.com',
  //serverUrl: 'http://10.211.55.24:8080',
  skipVerification: false,
  sphereOfTrustUrl: 'https://sphereoftrust-mobile.datingsphere.com',
  sphereOfTrustWebUrl: 'https://sphereoftrust.datingsphere.com',
  serverBasePath: 'v3',
  cloudinary: {
    cloudName: 'datingsphere-inc',
    apiKey: '649431615312629',
    apiSecret: '6PbYIsJNWJikKDzpSRfyEBSkRYo',
  },
  firebase: {
    apiKey: 'AIzaSyCY0QgNzCbUJYP256wNKNhlcyzermoyYNA',
    authDomain: 'datingsphere-1063.firebaseapp.com',
    databaseURL: 'https://datingsphere-1063.firebaseio.com',
    projectId: 'datingsphere-1063',
    storageBucket: 'datingsphere-1063.appspot.com',
    messagingSenderId: '702794586158',
    appId: '1:702794586158:web:3bf54661af80df1b60a7bf',
    measurementId: 'G-L28W021RFH',
  },
};
