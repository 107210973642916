import { StyleSheet, TextInput, Image } from 'react-native';
import React, { useContext, useEffect, useState } from 'react';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { SOTFlowType } from '../types';
import { Text, View } from '../components/Themed';
import ButtonStandard from '../components/buttons/ButtonStandard';
import NavHeader from '../components/LayoutSections/NavHeader';
import { SoTContext } from '../Global/SoTContext';
import Swiper from 'react-native-web-swiper';
import { globalStyles } from '../Global/GlobalStyles';

const slideWidth = 360;

type Props = NativeStackScreenProps<SOTFlowType>;
export default function ShareMediaReviewScreen({ navigation }: Props) {
  console.log('ShareMediaReviewScreen');

  const { /*user,*/ imageData, setImageData } = useContext(SoTContext);

  const [imageInFocus, setImageInFocus] = useState(imageData[0]); //{ name: "", data: "", caption: "" });

  useEffect(() => {
    // if (!imageInFocus) {
    //   setImageInFocus(imageData[0]);
    // }
  });

  useEffect(() => {}, [imageData, imageInFocus]);

  const onAnimationStartHandler = (data: any) => {
    //console.log("anim start",data);
  };

  const onAnimationEndHandler = (data: any) => {
    //console.log("anim end", data);
  };

  const onIndexChangedHandler = (data: any) => {
    console.log('index change', data);
    let newData = imageData[data];
    setImageInFocus(newData);
    console.table(imageInFocus);
  };

  const setNestedCaptionForImageInFocus = (text: string) => {
    let updatedImageData = imageData.map(imageItem => {
      if (imageItem.name === imageInFocus?.name) {
        imageItem.caption = text;
      }
      return imageItem;
    });
    setImageData(updatedImageData);
  };

  return (
    <View style={styles.container}>
      <NavHeader
        backButtonNavigation="ShareMedia"
        props={navigation}
        steps={[1, 1, 1, 1, 1, 1, 1]}
        width={globalStyles.container.width}
        height="112px"
      ></NavHeader>
      <View style={{ width: '90%', paddingLeft: 25, alignItems: 'flex-start' }}>
        <Text style={styles.title}>Share Media</Text>
        <View style={{ flexDirection: 'row' }}>
          <Text style={styles.subTitle}>Upload pictures or videos together.</Text>
        </View>
      </View>
      <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
      <View
        style={{ height: slideWidth, width: 360, marginBottom: 30, marginTop: 20, marginLeft: 18, margin: 'auto' }}
      >
        <Swiper
          onAnimationStart={data => {
            return onAnimationStartHandler(data);
          }}
          onAnimationEnd={data => {
            return onAnimationEndHandler(data);
          }}
          onIndexChanged={data => {
            return onIndexChangedHandler(data);
          }}
          controlsProps={{
            prevTitle: '',
            nextTitle: '',
            dotActiveStyle: {
              borderColor: '#FFFFFF',
              borderWidth: 1,
              backgroundColor: '#093f76',
            },
          }}
        >
          {imageData.map((imageItem, index) => (
            <View style={[styles.slideContainer, styles.slide]} key={index}>
              {/* <Text>{imageItem.name}</Text> */}
              <Image
                style={{ width: 360, height: 360, borderRadius: 8 }}
                source={{ uri: imageItem.uri }}
              />
            </View>
          ))}
        </Swiper>
      </View>
      <View style={styles.captionEntry}>
        <Text style={styles.fieldTopLabel}>Add Caption</Text>
        {/* {imageData.filter(iiFocus=>iiFocus.name === imageInFocus?.name).map(imageItem => */}
        {imageData.map((imageItem, index) => (
          <TextInput
            style={
              imageItem.name === imageInFocus?.name ? styles.captionInput : { display: 'none' }
            }
            placeholderTextColor="#ADB5BD"
            placeholder={`Enter caption here for ${imageItem.name}`}
            onChangeText={text => setNestedCaptionForImageInFocus(text)}
            key={index}
          ></TextInput>
        ))}
        <Text style={styles.entryHint}></Text>
      </View>
      <View style={{ width: '100%', position: 'absolute', bottom: 5 }}>
        <ButtonStandard
          label="Add Caption"
          onPress={() => navigation.navigate('Review')}
        ></ButtonStandard>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#FFFFFF',
    width: `${globalStyles.container.width}px`,
    margin: 'auto',
  },
  title: {
    paddingRight: 25,
    fontFamily: 'Ubuntu',
    fontWeight: '400',
    fontSize: 26,
    color: '#212529',
    marginBottom: 8,
  },
  subTitle: {
    fontFamily: 'Jost Regular',
    fontSize: 18,
    color: '#6C7570',
  },
  separator: {
    marginVertical: 10,
    marginBottom: 5,
    margin: 'auto',
    height: 1,
    backgroundColor: '#DEE2E6',
    width: '90%',
  },
  captionEntry: {
    width: '100%',
    paddingLeft: 25,
  },
  captionInput: {
    fontFamily: 'Jost Regular',
    fontSize: 18,
    color: '#212529',
    width: 'calc(100% - 25px)',
    height: 50,
    borderColor: '#ADB5BD',
    borderRadius: 8,
    borderWidth: 2,
    paddingLeft: 10,
  },
  entryHint: {
    color: '#6C7570',
    fontFamily: 'Jost Regular',
    fontSize: 14,
    marginTop: 15,
  },
  fieldTopLabel: {
    color: '#212529',
    fontFamily: 'Jost Regular',
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 6,
  },
  slideContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
  },
  slide: {
    width: slideWidth,
    height: slideWidth,
    borderRadius: 8,
    margin: 'auto',
    backgroundColor: '#BBBBBB',
  },
});
