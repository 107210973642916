import { Trait, Interest, LanguageItem } from './models';

export const defaultPositiveTraits: Trait[] = [
  {
    sectionName: 'Thought',
    label: 'Analytical',
  },
  {
    sectionName: 'Thought',
    label: 'Articulate',
  },
  {
    sectionName: 'Thought',
    label: 'Cautious',
  },
  {
    sectionName: 'Thought',
    label: 'Clever',
  },
  {
    sectionName: 'Thought',
    label: 'Curious',
  },
  {
    sectionName: 'Thought',
    label: 'Eloquent',
  },
  {
    sectionName: 'Thought',
    label: 'Insightful',
  },
  {
    sectionName: 'Thought',
    label: 'Logical',
  },
  {
    sectionName: 'Thought',
    label: 'Observant',
  },
  {
    sectionName: 'Thought',
    label: 'Open Minded',
  },
  {
    sectionName: 'Thought',
    label: 'Philosophical',
  },
  {
    sectionName: 'Thought',
    label: 'Wise',
  },
  {
    sectionName: 'Thought',
    label: 'Witty',
  },
  {
    sectionName: 'Expression',
    label: 'Bubbly',
  },
  {
    sectionName: 'Expression',
    label: 'Charismatic',
  },
  {
    sectionName: 'Expression',
    label: 'Charming',
  },
  {
    sectionName: 'Expression',
    label: 'Easy Going',
  },
  {
    sectionName: 'Expression',
    label: 'Elegant',
  },
  {
    sectionName: 'Expression',
    label: 'Expressive',
  },
  {
    sectionName: 'Expression',
    label: 'Free Spirited',
  },
  {
    sectionName: 'Expression',
    label: 'Funny',
  },
  {
    sectionName: 'Expression',
    label: 'Personable',
  },
  {
    sectionName: 'Expression',
    label: 'Playful',
  },
  {
    sectionName: 'Expression',
    label: 'Spunky',
  },
  {
    sectionName: 'Expression',
    label: 'Suave',
  },
  {
    sectionName: 'Personal',
    label: 'Confident',
  },
  {
    sectionName: 'Personal',
    label: 'Decisive',
  },
  {
    sectionName: 'Personal',
    label: 'Determined',
  },
  {
    sectionName: 'Personal',
    label: 'Economical',
  },
  {
    sectionName: 'Personal',
    label: 'Humble',
  },
  {
    sectionName: 'Personal',
    label: 'Organized',
  },
  {
    sectionName: 'Personal',
    label: 'Patient',
  },
  {
    sectionName: 'Personal',
    label: 'Resourceful',
  },
  {
    sectionName: 'Personal',
    label: 'Responsible',
  },
  {
    sectionName: 'Personal',
    label: 'Righteous',
  },
  {
    sectionName: 'Personal',
    label: 'Sophisticated',
  },
  {
    sectionName: 'Personal',
    label: 'Strong Willed',
  },
  {
    sectionName: 'Personal',
    label: 'Well Rounded',
  },
  {
    sectionName: 'Love',
    label: 'Appreciative',
  },
  {
    sectionName: 'Love',
    label: 'Compassionate',
  },
  {
    sectionName: 'Love',
    label: 'Considerate',
  },
  {
    sectionName: 'Love',
    label: 'Courteous',
  },
  {
    sectionName: 'Love',
    label: 'Generous',
  },
  {
    sectionName: 'Love',
    label: 'Genuine',
  },
  {
    sectionName: 'Love',
    label: 'Loving',
  },
  {
    sectionName: 'Love',
    label: 'Loyal',
  },
  {
    sectionName: 'Love',
    label: 'Passionate',
  },
  {
    sectionName: 'Love',
    label: 'Romantic',
  },
  {
    sectionName: 'Love',
    label: 'Sweet',
  },
  {
    sectionName: 'Love',
    label: 'Trusting',
  },
  {
    sectionName: 'Love',
    label: 'Understanding',
  },
];

export const defaultNeutralTraits: Trait[] = [
  {
    sectionName: 'Thought',
    label: 'Adaptive',
  },
  {
    sectionName: 'Thought',
    label: 'Ambitious',
  },
  {
    sectionName: 'Thought',
    label: 'Conservative',
  },
  {
    sectionName: 'Thought',
    label: 'Opinionated',
  },
  {
    sectionName: 'Thought',
    label: 'Opportunistic',
  },
  {
    sectionName: 'Thought',
    label: 'Optimistic',
  },
  {
    sectionName: 'Thought',
    label: 'Picky',
  },
  {
    sectionName: 'Thought',
    label: 'Perfectionist',
  },
  {
    sectionName: 'Thought',
    label: 'Private',
  },
  {
    sectionName: 'Thought',
    label: 'Results Driven',
  },
  {
    sectionName: 'Thought',
    label: 'Sarcastic',
  },
  {
    sectionName: 'Thought',
    label: 'Stubborn',
  },
  {
    sectionName: 'Expression',
    label: 'Aggressive',
  },
  {
    sectionName: 'Expression',
    label: 'Cocky',
  },
  {
    sectionName: 'Expression',
    label: 'Dramatic',
  },
  {
    sectionName: 'Expression',
    label: 'Frivolous',
  },
  {
    sectionName: 'Expression',
    label: 'Sassy',
  },
  {
    sectionName: 'Expression',
    label: 'Sensitive',
  },
  {
    sectionName: 'Expression',
    label: 'Serious',
  },
  {
    sectionName: 'Expression',
    label: 'Sexual',
  },
  {
    sectionName: 'Expression',
    label: 'Shy',
  },
  {
    sectionName: 'Expression',
    label: 'Stoic',
  },
  {
    sectionName: 'Expression',
    label: 'Wild',
  },
  {
    sectionName: 'Personal',
    label: 'Adventurous',
  },
  {
    sectionName: 'Personal',
    label: 'Blunt',
  },
  {
    sectionName: 'Personal',
    label: 'Clumsy',
  },
  {
    sectionName: 'Personal',
    label: 'Competitive',
  },
  {
    sectionName: 'Personal',
    label: 'Demanding',
  },
  {
    sectionName: 'Personal',
    label: 'Dependent',
  },
  {
    sectionName: 'Personal',
    label: 'Extravagant',
  },
  {
    sectionName: 'Personal',
    label: 'Impulsive',
  },
  {
    sectionName: 'Personal',
    label: 'Independent',
  },
  {
    sectionName: 'Personal',
    label: 'Indulgent',
  },
  {
    sectionName: 'Personal',
    label: 'Impatient',
  },
  {
    sectionName: 'Personal',
    label: 'Persuasive',
  },
  {
    sectionName: 'Personal',
    label: 'Protective',
  },
];

export const defaultInterests: Interest[] = [
  {
    sectionName: 'Staying In',
    label: 'Animals',
  },
  {
    sectionName: 'Staying In',
    label: 'Art',
  },
  {
    sectionName: 'Staying In',
    label: 'Board Games',
  },
  {
    sectionName: 'Staying In',
    label: 'Card Playing',
  },
  {
    sectionName: 'Staying In',
    label: 'Fashion',
  },
  {
    sectionName: 'Staying In',
    label: 'Movies / TV Shows',
  },
  {
    sectionName: 'Staying In',
    label: 'Music',
  },
  {
    sectionName: 'Staying In',
    label: 'Politics',
  },
  {
    sectionName: 'Staying In',
    label: 'Reading',
  },
  {
    sectionName: 'Staying In',
    label: 'Religion / Spirituality',
  },
  {
    sectionName: 'Staying In',
    label: 'Video Games',
  },
  {
    sectionName: 'Staying In',
    label: 'Watching Sports',
  },
  {
    sectionName: 'Staying In',
    label: 'Writing',
  },
  {
    sectionName: 'Going Out',
    label: 'Beach',
  },
  {
    sectionName: 'Going Out',
    label: 'Broadway Shows',
  },
  {
    sectionName: 'Going Out',
    label: 'Conversation',
  },
  {
    sectionName: 'Going Out',
    label: 'Dancing',
  },
  {
    sectionName: 'Going Out',
    label: 'Food',
  },
  {
    sectionName: 'Going Out',
    label: 'Opera',
  },
  {
    sectionName: 'Going Out',
    label: 'Outdoor Activities',
  },
  {
    sectionName: 'Going Out',
    label: 'Playing Sports',
  },
  {
    sectionName: 'Going Out',
    label: 'Shopping',
  },
  {
    sectionName: 'Going Out',
    label: 'Traveling',
  },
];

// Alphabet sorted
export const defaultLanguagesSpoken: LanguageItem[] = [
  { name: 'English', version: 'US', proficiency: '' },
  { name: 'Arabic', version: 'Saudi Arabia', proficiency: '' },
  { name: 'Bengali', version: 'Bangladesh', proficiency: '' },
  { name: 'Cantonese', version: 'China', proficiency: '' },
  { name: 'French', version: 'France', proficiency: '' },
  { name: 'German', version: 'Germany', proficiency: '' },
  { name: 'Hindi', version: 'India', proficiency: '' },
  { name: 'Italian', version: 'Italy', proficiency: '' },
  { name: 'Japanese', version: 'Japan', proficiency: '' },
  { name: 'Javanese', version: 'Indonesia', proficiency: '' },
  { name: 'Korean', version: 'Korea', proficiency: '' },
  { name: 'Lahnda', version: 'Pakistan', proficiency: '' },
  { name: 'Mandarin', version: 'China', proficiency: '' },
  { name: 'Portuguese', version: 'Portugal', proficiency: '' },
  { name: 'Russian', version: 'Russian Federation', proficiency: '' },
  { name: 'Spanish', version: 'Spain', proficiency: '' },
  { name: 'Taiwanese', version: 'China', proficiency: '' },
  { name: 'Tamil', version: 'India', proficiency: '' },
  { name: 'Telugu', version: 'India', proficiency: '' },
  { name: 'Urdu', version: 'Pakistan', proficiency: '' },
];

export const languageProficiencyMap: any = {
  Interested: 0,
  Beginner: 1,
  Intermediate: 2,
  Advanced: 3,
  Fluent: 4,
  Native: 5,
};
