import React, { useState } from 'react';
import { TextInput, TextInputProps, StyleSheet } from 'react-native';

type ExpandingTextInputProps = TextInputProps & {
  minHeight?: number;
};

const ExpandingTextInput = (props: ExpandingTextInputProps) => {
  const [height, setHeight] = useState(0);

  const handleContentSizeChange = (event: any) => {
    setHeight(event.nativeEvent.contentSize.height);
  };

  const { minHeight = 50, ...restProps } = props;

  return (
    <TextInput
      {...restProps}
      multiline
      onContentSizeChange={handleContentSizeChange}
      style={{ height: Math.max(minHeight, height), ...styles.userInputField }}
    />
  );
};

const styles = StyleSheet.create({
  userInputField: {
    fontFamily: 'Jost Regular',
    fontSize: 18,
    color: '#212529',
    width: '100%',
    borderColor: '#ADB5BD',
    borderRadius: 8,
    borderWidth: 2,
    paddingLeft: 10,
    paddingTop: 9,
    paddingBottom: 9,
  },
});

export default ExpandingTextInput;
