import { StyleSheet, TouchableOpacity } from 'react-native';
import React, { useState } from 'react';
import { Text, View } from '../../components/Themed';

export interface DSTrueFalseProps {
  value: any;
  valueSetter: Function;
}

export default function DSTrueFalse(trueFalseProps: DSTrueFalseProps) {
  const [decision, setDecision] = useState(trueFalseProps.value);

  const handleDecisionPress = (choice: any) => {
    setDecision(choice);
    trueFalseProps.valueSetter(choice);
  };

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
      <TouchableOpacity
        onPress={() => {
          handleDecisionPress('true');
        }}
      >
        <View style={decision === 'true' ? styles.tfButtonsSelected : styles.tfButtons}>
          <Text style={decision === 'true' ? styles.tfButtonTextSelected : styles.tfButtonText}>
            True
          </Text>
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          handleDecisionPress('false');
        }}
      >
        <View style={decision === 'false' ? styles.tfButtonsSelected : styles.tfButtons}>
          <Text style={decision === 'false' ? styles.tfButtonTextSelected : styles.tfButtonText}>
            False
          </Text>
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          handleDecisionPress('notsure');
        }}
      >
        <View style={decision === 'notsure' ? styles.tfButtonsSelected : styles.tfButtons}>
          <Text style={decision === 'notsure' ? styles.tfButtonTextSelected : styles.tfButtonText}>
            Not Sure
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {},
  tfButtons: {
    backgroundColor: '#F8F9FA',
    borderRadius: 80,
    borderWidth: 1,
    borderColor: '#C5D9ED',
    width: 107,
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tfButtonText: {
    fontFamily: 'Jost Regular',
    fontSize: 16,
    color: '#093F76',
  },
  tfButtonsSelected: {
    backgroundColor: '#D9E8F6',
    borderRadius: 80,
    borderWidth: 2,
    borderColor: '#093F76',
    width: 107,
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tfButtonTextSelected: {
    fontFamily: 'Jost Regular',
    fontSize: 16,
    fontWeight: '600',
    color: '#093F76',
  },
});
