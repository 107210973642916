import React from 'react';

export interface SvgProps {
  width?: string;
  height?: string;
  size?: string;
}

export default function XClose(svgProps: SvgProps) {
  return (
    <svg
      width={svgProps.width || '16'}
      height={svgProps.height || '16'}
      viewBox={`0 0 ${svgProps.width || '16'} ${svgProps.width || '16'}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.05932 7.99973L15.2843 1.7841C15.4039 1.63838 15.465 1.45338 15.4558 1.26509C15.4465 1.0768 15.3676 0.898686 15.2343 0.765385C15.101 0.632084 14.9229 0.553131 14.7346 0.543884C14.5463 0.534636 14.3613 0.595755 14.2156 0.715352L7.99995 6.94035L1.78432 0.715352C1.6386 0.595755 1.4536 0.534636 1.26531 0.543884C1.07702 0.553131 0.898905 0.632084 0.765604 0.765385C0.632303 0.898686 0.55335 1.0768 0.544103 1.26509C0.534855 1.45338 0.595974 1.63838 0.715571 1.7841L6.94057 7.99973L0.715571 14.2154C0.574735 14.3575 0.495728 14.5496 0.495728 14.7497C0.495728 14.9499 0.574735 15.1419 0.715571 15.2841C0.858927 15.4227 1.05054 15.5002 1.24995 15.5002C1.44936 15.5002 1.64097 15.4227 1.78432 15.2841L7.99995 9.0591L14.2156 15.2841C14.3589 15.4227 14.5505 15.5002 14.7499 15.5002C14.9494 15.5002 15.141 15.4227 15.2843 15.2841C15.4252 15.1419 15.5042 14.9499 15.5042 14.7497C15.5042 14.5496 15.4252 14.3575 15.2843 14.2154L9.05932 7.99973Z"
        fill="#212529"
      />
    </svg>
  );
}
