import { StyleSheet, TouchableOpacity } from 'react-native';
import React, { useEffect, useState } from 'react';
import { Text, View } from '../../components/Themed';
import { globalStyles } from '../../Global/GlobalStyles';

export interface DSRadioButtonProps {
  width?: string;
  height?: string;
  descHeader: string;
  description: string;
  label?: string;
  onPress: any;
  selected?: boolean;
  style?: any;
}

export default function DSRadioButtonWithDescription(svgProps: DSRadioButtonProps) {
  useEffect(() => {
    setIsSelected(svgProps.selected);
  }, [svgProps.selected]);

  const [isSelected, setIsSelected] = useState(svgProps.selected);
  const innerPress = () => {
    setIsSelected(true);
    svgProps.onPress.call();
  };

  return (
    <TouchableOpacity
      style={[{ alignItems: 'center', marginBottom: 20, marginTop: 10 }, svgProps.style]}
      onPress={innerPress}
    >
      <View style={{ flexDirection: 'row' }}>
        <View style={styles.buttonContainer}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {isSelected && <circle cx="12" cy="12" r="6" fill="#093F76" />}
            {isSelected && <circle cx="12" cy="12" r="11" stroke="#093F76" strokeWidth="2" />}
            {!isSelected && <circle cx="12" cy="12" r="11" stroke="#6C757D" strokeWidth="2" />}
          </svg>
        </View>
        <View style={{ width: '80%', marginLeft: 23 }}>
          <Text style={styles.descHeader}>{svgProps.descHeader}</Text>
          <Text style={styles.description}>{svgProps.description}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    marginLeft: 20,
    marginTop: 5,
  },
  descHeader: {
    fontFamily: 'Jost Regular',
    fontSize: globalStyles.container.height < 800 ? 16 : 18,
    fontWeight: '500',
    color: '#212529',
  },
  description: {
    fontFamily: 'Jost Regular',
    fontSize: globalStyles.container.height < 800 ? 16 : 18,
    color: '#6c7570',
  },
});
