import { ScrollView, StyleSheet, TextInput } from 'react-native';
import React, { useContext } from 'react';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { SOTFlowType } from '../types';
import { Text, View } from '../components/Themed';
import ButtonStandard from '../components/buttons/ButtonStandard';
import NavHeader from '../components/LayoutSections/NavHeader';
import { Picker } from 'react-native';
import { SoTContext } from '../Global/SoTContext';
import { globalStyles } from '../Global/GlobalStyles';

const DEFAULT_TEXT = 'Choose';
const relationshipOptions = [DEFAULT_TEXT, 'Close Friend', 'Exes', 'Family Member'];
const yearsKnownOptions = [DEFAULT_TEXT, '1', '3', '5', '10', '20+'];

type Props = NativeStackScreenProps<SOTFlowType>;
export default function LetsBeginScreen({ navigation }: Props) {
  console.log('LetsBeginScreen');

  const {
    user,
    firstName,
    setFirstName,
    relationship,
    setRelationship,
    yearsKnown,
    setYearsKnown,
  } = useContext(SoTContext);

  const checkValid = () => {
    let itemsNotBlank = firstName !== '' && relationship !== '' && yearsKnown !== '';
    let itemsNotDefault = relationship !== DEFAULT_TEXT && yearsKnown !== DEFAULT_TEXT;
    return itemsNotBlank && itemsNotDefault;
  };

  return (
    <View style={styles.container}>
      <NavHeader
        props={navigation}
        steps={[1, 0, 0, 0, 0, 0, 0]}
        width={globalStyles.container.width}
        height="112px"
      ></NavHeader>
      <ScrollView style={{ paddingTop: 0 }}>
        <View style={{ flex: 1, paddingHorizontal: 20 }}>
          <View style={{ width: '100%', alignItems: 'flex-start' }}>
            <Text style={styles.title}>Let's begin! How do you know {user?.first}?</Text>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.subTitle}>
                Tell us a little about yourself and your relationship with {user?.first}.
              </Text>
            </View>
          </View>
          <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
          <View
            style={{
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <View style={styles.userInfoEntry}>
              <Text style={styles.fieldTopLabel}>First Name</Text>
              <TextInput
                placeholderTextColor="#ADB5BD"
                placeholder="Enter Name Here"
                value={firstName}
                onChangeText={text => {
                  setFirstName(text);
                }}
                style={styles.userInputField}
              ></TextInput>
            </View>
            <View style={styles.userInfoEntry}>
              <Text style={styles.fieldTopLabel}>Relationship</Text>
              <div className="selectdiv">
                <Picker
                  style={styles.select}
                  selectedValue={relationship || relationshipOptions[0]}
                  itemStyle={{ backgroundColor: 'green' }}
                  onValueChange={(value: any) => {
                    setRelationship(value);
                  }}
                >
                  {relationshipOptions.map(relo => (
                    <Picker.Item label={relo} value={relo} key={relo} />
                  ))}
                </Picker>
              </div>
            </View>
            <View style={styles.userInfoEntry}>
              <Text style={styles.fieldTopLabel}>Years Known</Text>
              <div className="selectdiv">
                <Picker
                  style={styles.select}
                  selectedValue={yearsKnown || yearsKnownOptions[0]}
                  onValueChange={(value: any) => {
                    setYearsKnown(value);
                  }}
                >
                  {yearsKnownOptions.map(yknown => (
                    <Picker.Item label={yknown} value={yknown} key={yknown} />
                  ))}
                </Picker>
              </div>
            </View>
          </View>
        </View>
      </ScrollView>
      <View
        style={{
          width: '100%',
          paddingBottom: globalStyles.container.height < 800 ? 10 : 20,
          paddingHorizontal: 20,
        }}
      >
        <ButtonStandard
          label="Next"
          disabled={!checkValid()}
          onPress={() => navigation.navigate('Vouch')}
        ></ButtonStandard>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#FFFFFF',
    width: `${globalStyles.container.width}px`,
    margin: 'auto',
    background: 'FFFFFF',
  },
  title: {
    paddingRight: 25,
    fontFamily: 'Ubuntu',
    fontWeight: '500',
    fontSize: globalStyles.container.height < 800 ? 22 : 26,
    color: '#212529',
    marginBottom: 8,
  },
  subTitle: {
    fontFamily: 'Jost Regular',
    fontSize: globalStyles.container.height < 800 ? 16 : 18,
    color: '#6C7570',
  },
  separator: {
    marginTop: 10,
    margin: 'auto',
    height: 1,
    backgroundColor: '#DEE2E6',
    width: '100%',
  },
  verifyNumber: {
    fontFamily: 'Jost Regular',
    fontSize: 18,
    color: '#6C7570',
    fontWeight: '600',
  },
  knowInput: {
    fontFamily: 'Jost Regular',
    fontSize: 18,
    color: '#212529',
    width: '90%',
    height: 50,
    borderColor: '#ADB5BD',
    borderRadius: 8,
    borderWidth: 2,
    textAlign: 'center',
  },
  entryHint: {
    color: '#6C7570',
    fontFamily: 'Jost Regular',
    fontSize: 14,
    marginTop: 15,
  },
  requestAgain: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  userInfoEntry: {
    width: '100%',
    marginTop: 20,
  },
  fieldTopLabel: {
    color: '#212529',
    fontFamily: 'Jost Regular',
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 6,
  },
  userInputField: {
    fontFamily: 'Jost Regular',
    fontSize: 18,
    color: '#212529',
    width: '100%',
    height: 50,
    borderColor: '#ADB5BD',
    borderRadius: 8,
    borderWidth: 2,
    paddingLeft: 10,
  },
  select: {
    fontFamily: 'Jost Regular',
    fontSize: 18,
    color: '#212529',
    width: '100%',
    height: 50,
    borderColor: '#ADB5BD',
    borderRadius: 8,
    borderWidth: 2,
    paddingLeft: 10,
  },
  pickerWrapper: {
    borderColor: 'blue', //blurColor,
    borderWidth: 1,
    backgroundColor: '#273137',
    borderRadius: 4,
  },
  pickerIcon: {
    color: 'red', // inputColor,
    position: 'absolute',
    bottom: 15,
    right: 10,
    fontSize: 20,
  },

  pickerContent: {
    color: 'orange', // inputColor,
    backgroundColor: 'transparent',
  },
});
