import { ScrollView, StyleSheet } from 'react-native';
import React, { useContext, useEffect, useState } from 'react';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { SOTFlowType } from '../types';
import { Text, View } from '../components/Themed';
import ButtonStandard from '../components/buttons/ButtonStandard';
import NavHeader from '../components/LayoutSections/NavHeader';
import TraitsMenu from '../components/Traits/TraitsMenu';
import { SoTContext } from '../Global/SoTContext';
import { globalStyles } from '../Global/GlobalStyles';
import { defaultPositiveTraits } from '../Global/const';

const selectionMax = 4;

type Props = NativeStackScreenProps<SOTFlowType>;
export default function PositiveTraitsScreen({ navigation }: Props) {
  console.log('PositiveTraitsScreen');

  const { traitsPositive, setTraitsPositive } = useContext(SoTContext);

  const [traitCount, setTraitCount] = useState(0);

  useEffect(() => {
    setTraitCount(traitsPositive.filter(t => t.selected).length);
  }, [traitsPositive]);

  const compileResults = (results: any) => {
    const target = results?.itemClicked;
    let landedCount = 0;
    const updatedTraits = traitsPositive.map(ti => {
      if (ti.label === target) {
        if (ti.selected) {
          ti.selected = false;
        } else if (traitCount < selectionMax) {
          ti.selected = true;
        }
      }
      if (ti.selected) {
        landedCount++;
      }
      return ti;
    });

    setTraitsPositive(updatedTraits);
    setTraitCount(landedCount);
  };

  return (
    <View style={styles.container}>
      <NavHeader
        backButtonNavigation="Describe"
        props={navigation}
        steps={[1, 1, 1, 1, 0, 0, 0]}
        width={globalStyles.container.width}
        height="112px"
      ></NavHeader>
      <ScrollView style={{ paddingHorizontal: 20 }}>
        <View style={{ width: '100%' }}>
          <Text style={styles.title}>Choose 4 positive traits</Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.subTitle}>
              Traits that are generally viewed as positive in someone.
            </Text>
          </View>
        </View>
        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
        <View style={{ marginTop: 0 }}>
          <TraitsMenu
            feedbackFunction={compileResults}
            sectionName="Thought"
            optionItems={traitsPositive.filter(t => t.sectionName === 'Thought')}
          ></TraitsMenu>
          <TraitsMenu
            feedbackFunction={compileResults}
            sectionName="Expression"
            optionItems={traitsPositive.filter(t => t.sectionName === 'Expression')}
          ></TraitsMenu>
          <TraitsMenu
            feedbackFunction={compileResults}
            sectionName="Personal"
            optionItems={traitsPositive.filter(t => t.sectionName === 'Personal')}
          ></TraitsMenu>
          <TraitsMenu
            feedbackFunction={compileResults}
            sectionName="Love"
            optionItems={traitsPositive.filter(t => t.sectionName === 'Love')}
          ></TraitsMenu>
        </View>
      </ScrollView>
      <View
        style={{
          width: '100%',
          paddingBottom: globalStyles.container.height < 800 ? 10 : 20,
          paddingHorizontal: 20,
        }}
      >
        <ButtonStandard
          disabled={!(traitCount === selectionMax)}
          label={`Next (${traitCount}/${selectionMax})`}
          onPress={() => navigation.navigate('NeutralTraits')}
        ></ButtonStandard>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#FFFFFF',
    width: `${globalStyles.container.width}px`,
    margin: 'auto',
  },
  title: {
    paddingRight: 25,
    fontFamily: 'Ubuntu',
    fontWeight: '400',
    fontSize: globalStyles.container.height < 800 ? 22 : 26,
    color: '#212529',
    marginBottom: 8,
  },
  subTitle: {
    fontFamily: 'Jost Regular',
    fontSize: globalStyles.container.height < 800 ? 16 : 18,
    color: '#6C7570',
  },
  separator: {
    marginVertical: 10,
    margin: 'auto',
    height: 1,
    backgroundColor: '#DEE2E6',
    width: '100%',
  },
});
