import { Dimensions } from 'react-native';

const { width, height } = Dimensions.get('window');
const maxWidth = 430;
console.log(width);

export const globalStyles = {
  container: {
    width: width < maxWidth ? width : maxWidth,
    height: height,
  },
  headerOffset: {
    distance: -150,
  },
};
