import { ScrollView, StyleSheet } from 'react-native';
import React, { useContext, useEffect, useState } from 'react';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { SOTFlowType } from '../types';
import { Text, View } from '../components/Themed';
import ButtonStandard from '../components/buttons/ButtonStandard';
import NavHeader from '../components/LayoutSections/NavHeader';
import DSTrueFalse from '../components/buttons/DSTrueFalse';
import { SoTContext } from '../Global/SoTContext';
import { globalStyles } from '../Global/GlobalStyles';
import { SphereOfTrust } from '../Global/models.dto';

type Props = NativeStackScreenProps<SOTFlowType>;
export default function VouchScreen({ navigation }: Props) {
  console.log('VouchScreen');

  const {
    api,
    user,
    ageCheck,
    setAgeCheck,
    bodyTypeCheck,
    setBodyTypeCheck,
    heightCheck,
    setHeightCheck,
    educationCheck,
    setEducationCheck,
    jobTitleCheck,
    setJobTitleCheck,
    hasKidsCheck,
    setHasKidsCheck,
    smokesTobaccoCheck,
    setSmokesTobaccoCheck,
    firstName,
    yearsKnown,
    relationship,
    userExtraData,
    setUserExtraData,
  } = useContext(SoTContext);
  const [height, setHeight] = useState('');

  useEffect(() => {
    setHeight(`${user.height[0]}'${user.height[1]}"`);
  }, []);

  const readyMoveNext = (fields: Array<string>) => {
    return fields?.filter(f => f === '')?.length === 0;
  };

  const onNext = async () => {
    try {
      const req = {
        ...userExtraData,
        firstName,
        relationship,
        yearsKnown: +yearsKnown.replace(/\D/g, ''),
        valid: {
          age: ageCheck === 'true',
          body: bodyTypeCheck === 'true',
          height: heightCheck === 'true',
          education: educationCheck === 'true',
          occupation: jobTitleCheck === 'true',
          hasKids: hasKidsCheck === 'true',
          smoke: smokesTobaccoCheck === 'true',
        },
        lastPage: userExtraData.completed ? 'Review' : 'Describe',
      };
      setUserExtraData(req);
      console.log('save ', req);
      await api.setStoredDetails(userExtraData?.id, req);
      navigation.navigate('Describe');
    } catch (error) {
      console.log('error : ', error);
    }
  };

  return (
    <View style={styles.container}>
      <NavHeader
        backButtonNavigation="LetsBegin"
        props={navigation}
        steps={[1, 1, 0, 0, 0, 0, 0]}
        width={globalStyles.container.width}
        height="112px"
      ></NavHeader>
      <ScrollView style={{ flex: 1 }}>
        <View style={{ width: '100%', paddingHorizontal: 20, alignItems: 'flex-start' }}>
          <Text style={styles.title}>Vouch for {user?.first}</Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.subTitle}>
              Help {user?.first}'s matches know how credible{' '}
              {user?.gender.toLowerCase() === 'male' ? 'his' : 'her'} information is.
            </Text>
          </View>
        </View>
        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
        <View style={{ width: '95%', paddingLeft: 25, marginBottom: 20 }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.fieldTopLabel}>Age:</Text>
            <Text style={styles.fieldTopValue}>{user?.age}</Text>
          </View>
          <DSTrueFalse value={ageCheck} valueSetter={setAgeCheck}></DSTrueFalse>
        </View>
        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
        <View style={{ width: '95%', paddingLeft: 25, marginBottom: 20 }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.fieldTopLabel}>Body Type:</Text>
            <Text style={styles.fieldTopValue}>{user?.body}</Text>
          </View>
          <DSTrueFalse value={bodyTypeCheck} valueSetter={setBodyTypeCheck}></DSTrueFalse>
        </View>
        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
        <View style={{ width: '95%', paddingLeft: 25, marginBottom: 20 }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.fieldTopLabel}>Height:</Text>
            <Text style={styles.fieldTopValue}>{height}</Text>
          </View>
          <DSTrueFalse value={heightCheck} valueSetter={setHeightCheck}></DSTrueFalse>
        </View>
        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
        <View style={{ width: '95%', paddingLeft: 25, marginBottom: 20 }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.fieldTopLabel}>Education:</Text>
            <Text style={styles.fieldTopValue}>
              {user?.education?.degree}, {user?.education?.school}
            </Text>
          </View>
          <DSTrueFalse value={educationCheck} valueSetter={setEducationCheck}></DSTrueFalse>
        </View>
        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
        <View style={{ width: '95%', paddingLeft: 25, marginBottom: 20 }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.fieldTopLabel}>Job Title:</Text>
            <Text style={styles.fieldTopValue}>{user?.occupation}</Text>
          </View>
          <DSTrueFalse value={jobTitleCheck} valueSetter={setJobTitleCheck}></DSTrueFalse>
        </View>
        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
        <View style={{ width: '95%', paddingLeft: 25, marginBottom: 20 }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.fieldTopLabel}>Has Kids:</Text>
            <Text style={styles.fieldTopValue}>{user?.hasKids}</Text>
          </View>
          <DSTrueFalse value={hasKidsCheck} valueSetter={setHasKidsCheck}></DSTrueFalse>
        </View>
        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
        <View style={{ width: '95%', paddingLeft: 25, marginBottom: 20 }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.fieldTopLabel}>Smokes Tobacco:</Text>
            <Text style={styles.fieldTopValue}>{user?.smoke}</Text>
          </View>
          <DSTrueFalse value={smokesTobaccoCheck} valueSetter={setSmokesTobaccoCheck}></DSTrueFalse>
        </View>

        <View
          style={{
            width: '100%',
            marginTop: 40,
            paddingBottom: globalStyles.container.height < 800 ? 10 : 20,
            paddingHorizontal: 20,
          }}
        >
          <ButtonStandard
            disabled={
              !readyMoveNext([
                ageCheck,
                bodyTypeCheck,
                heightCheck,
                educationCheck,
                jobTitleCheck,
                hasKidsCheck,
                smokesTobaccoCheck,
              ])
            }
            label="Next"
            onPress={onNext}
          ></ButtonStandard>
        </View>
      </ScrollView>
    </View>
  );
}
// background: rgb(20,76,135);
// background: linear-gradient(180deg, rgba(20,76,135,1) 0%, rgba(9,63,118,1) 49%, rgba(4,54,103,1) 98%);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#FFFFFF',
    width: `${globalStyles.container.width}px`,
    margin: 'auto',
    background: 'FFFFFF',
  },
  title: {
    paddingRight: 25,
    fontFamily: 'Ubuntu',
    fontWeight: '500',
    fontSize: globalStyles.container.height < 800 ? 22 : 26,
    color: '#212529',
    marginBottom: 8,
  },
  subTitle: {
    fontFamily: 'Jost Regular',
    fontSize: globalStyles.container.height < 800 ? 16 : 18,
    color: '#6C7570',
  },
  separator: {
    marginTop: 10,
    marginBottom: 20,
    margin: 'auto',
    height: 1,
    backgroundColor: '#DEE2E6',
    width: '90%',
  },
  verifyNumber: {
    fontFamily: 'Jost Regular',
    fontSize: 18,
    color: '#6C7570',
    fontWeight: '600',
  },
  knowInput: {
    fontFamily: 'Jost Regular',
    fontSize: 18,
    color: '#212529',
    width: '90%',
    height: 50,
    borderColor: '#ADB5BD',
    borderRadius: 8,
    borderWidth: 2,
    textAlign: 'center',
  },
  entryHint: {
    color: '#6C7570',
    fontFamily: 'Jost Regular',
    fontSize: 14,
    marginTop: 15,
  },
  requestAgain: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  fieldTopLabel: {
    color: '#212529',
    fontFamily: 'Jost Regular',
    fontSize: 16,
    fontWeight: '500',
    marginBottom: 15,
  },
  fieldTopValue: {
    color: '#212529',
    fontFamily: 'Jost Regular',
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 15,
    marginLeft: 5,
  },
});
