import React from 'react';

export interface SvgProps {
  width?: string;
  height?: string;
  size?: string;
}

export default function InviteFaceBook(svgProps: SvgProps) {
  return (
    <svg
      width={svgProps.width || '48'}
      height={svgProps.height || '48'}
      viewBox={`0 0 ${svgProps.size || 48} ${svgProps.size || 48}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.5" cy="24" r="24" fill="#F8F9FA" />
      <path
        d="M34.25 23.9997C34.2451 26.3818 33.3709 28.6802 31.7915 30.4633C30.212 32.2465 28.0359 33.3918 25.6718 33.6841C25.6191 33.6893 25.5658 33.6835 25.5154 33.6671C25.465 33.6507 25.4185 33.6241 25.3789 33.5888C25.3393 33.5536 25.3074 33.5106 25.2852 33.4624C25.263 33.4143 25.251 33.3621 25.25 33.3091V26.2497H27.5C27.6025 26.2499 27.704 26.2291 27.7982 26.1885C27.8924 26.1479 27.9773 26.0884 28.0476 26.0137C28.1179 25.9391 28.1721 25.8507 28.2069 25.7543C28.2417 25.6578 28.2564 25.5552 28.25 25.4528C28.2334 25.2589 28.1438 25.0785 27.9994 24.948C27.855 24.8175 27.6665 24.7466 27.4718 24.7497H25.25V22.4997C25.25 22.1019 25.408 21.7204 25.6893 21.4391C25.9706 21.1578 26.3521 20.9997 26.75 20.9997H28.25C28.3525 20.9999 28.454 20.9791 28.5482 20.9385C28.6424 20.8979 28.7273 20.8384 28.7976 20.7637C28.8679 20.6891 28.9221 20.6007 28.9569 20.5043C28.9917 20.4078 29.0064 20.3052 29 20.2028C28.9834 20.0089 28.8938 19.8285 28.7494 19.698C28.605 19.5675 28.4165 19.4966 28.2218 19.4997H26.75C25.9543 19.4997 25.1913 19.8158 24.6287 20.3784C24.066 20.941 23.75 21.7041 23.75 22.4997V24.7497H21.5C21.3974 24.7495 21.2959 24.7704 21.2017 24.8109C21.1075 24.8515 21.0226 24.911 20.9524 24.9857C20.8821 25.0604 20.8278 25.1487 20.793 25.2452C20.7582 25.3416 20.7436 25.4442 20.75 25.5466C20.7666 25.7405 20.8561 25.9209 21.0005 26.0514C21.1449 26.1819 21.3335 26.2528 21.5281 26.2497H23.75V33.3091C23.7502 33.3617 23.7394 33.4137 23.7183 33.4618C23.6972 33.5099 23.6661 33.5531 23.6272 33.5884C23.5883 33.6238 23.5424 33.6506 23.4925 33.6671C23.4426 33.6836 23.3898 33.6894 23.3375 33.6841C18.3875 33.0841 14.5625 28.781 14.7593 23.6341C14.8699 21.0923 15.9695 18.6944 17.8236 16.9523C19.6777 15.2101 22.1393 14.2616 24.683 14.3093C27.2267 14.3571 29.651 15.3972 31.4385 17.2077C33.2259 19.0181 34.2349 21.4556 34.25 23.9997Z"
        fill="#093F76"
      />
    </svg>
  );
}
