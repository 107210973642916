import { ScrollView, StyleSheet } from 'react-native';
import React, { useContext, useEffect, useState } from 'react';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { SOTFlowType } from '../types';
import { Text, View } from '../components/Themed';
import ButtonStandard from '../components/buttons/ButtonStandard';
import NavHeader from '../components/LayoutSections/NavHeader';
import TraitsMenu from '../components/Traits/TraitsMenu';
import { SoTContext, Trait } from '../Global/SoTContext';
import { globalStyles } from '../Global/GlobalStyles';

const selectionMax = 1;

type Props = NativeStackScreenProps<SOTFlowType>;
export default function DominantTraitScreen({ navigation }: Props) {
  console.log('DominantTraitScreen');

  const {
    api,
    user,
    traitsPositive,
    traitsNeutral,
    traitsDominant,
    setTraitsDominant,
    personality,
    userExtraData,
    setUserExtraData,
  } = useContext(SoTContext);
  const [traitCount, setTraitCount] = useState(0);
  const [allSelectedTraits, setAllSelectedTraits] = useState<Trait[]>([]);

  useEffect(() => {
    let traits = traitsPositive
      .concat(traitsNeutral)
      .filter(pt => pt?.selected)
      .map(pt => {
        return {
          ...pt,
          selected: pt.label === traitsDominant,
        };
      });

    setAllSelectedTraits(traits);
    setTraitCount(traits.filter(pt => pt.selected).length);
  }, [traitsPositive, traitsNeutral]);

  const compileResults = (results: any) => {
    console.log('compileResults', results);

    let target = results?.itemClicked;
    setTraitsDominant(target);
    let updatedAllTraits = allSelectedTraits.map(ast => {
      if (ast.label.toUpperCase() === target.toUpperCase()) {
        ast.selected = true;
      } else {
        ast.selected = false;
      }
      return ast;
    });
    // if(shareTraitDominant !== "") {
    setTraitCount(target ? 1 : 0);
    setAllSelectedTraits(updatedAllTraits);
  };

  const onNext = async () => {
    try {
      const req = {
        ...userExtraData,
        personality,
        positiveTraits: traitsPositive.filter(t => t.selected).map(t => t.label),
        neutralTraits: traitsNeutral.filter(t => t.selected).map(t => t.label),
        dominantTrait: traitsDominant,
        lastPage: userExtraData.completed ? 'Review' : 'ShareInsight',
      };
      setUserExtraData(req);
      console.log('save ', req);
      await api.setStoredDetails(userExtraData?.id, req);
      navigation.navigate('ShareInsight');
    } catch (error) {
      console.log('error : ', error);
    }
  };

  return (
    <View style={styles.container}>
      <NavHeader
        backButtonNavigation="NeutralTraits"
        props={navigation}
        steps={[1, 1, 1, 1, 1, 0, 0]}
        width={globalStyles.container.width}
        height="112px"
      ></NavHeader>
      <ScrollView style={{ paddingHorizontal: 20 }}>
        <View style={{ width: '100%' }}>
          <Text style={styles.title}>Choose a dominant trait</Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.subTitle}>Trait that stands out the most in {user?.first}.</Text>
          </View>
        </View>
        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
        <View style={{ marginTop: 0 }}>
          {allSelectedTraits.length > 0 && (
            <TraitsMenu
              feedbackFunction={compileResults}
              sectionName="Selected Traits"
              optionItems={allSelectedTraits}
            ></TraitsMenu>
          )}
        </View>
      </ScrollView>
      <View
        style={{
          width: '100%',
          paddingBottom: globalStyles.container.height < 800 ? 10 : 20,
          paddingHorizontal: 20,
        }}
      >
        <ButtonStandard
          disabled={!(traitCount === selectionMax)}
          label={`Next (${traitCount}/${selectionMax})`}
          onPress={onNext}
        ></ButtonStandard>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#FFFFFF',
    width: `${globalStyles.container.width}px`,
    margin: 'auto',
  },
  title: {
    paddingRight: 25,
    fontFamily: 'Ubuntu',
    fontWeight: '400',
    fontSize: globalStyles.container.height < 800 ? 22 : 26,
    color: '#212529',
    marginBottom: 8,
  },
  subTitle: {
    fontFamily: 'Jost Regular',
    fontSize: globalStyles.container.height < 800 ? 16 : 18,
    color: '#6C7570',
  },
  separator: {
    marginVertical: 10,
    margin: 'auto',
    height: 1,
    backgroundColor: '#DEE2E6',
    width: '100%',
  },
});
